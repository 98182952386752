import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { LinkTo } from '.'

const Article = ({ title, date, excerpt, slug, timeToRead, categories }) => {
  return <section className="articleitem">
    <h2>
      <Link to={slug}>{title}</Link>
    </h2>
    <div class="byline">
      {date} &mdash; {timeToRead} Min Read &mdash; In{' '}
        {categories.map((cat, i) => (
          <React.Fragment key={cat}>
            {!!i && ', '}
            <LinkTo.Category name={cat}/>
          </React.Fragment>
        ))}
    </div>
    <div class="excerpt">
      {excerpt}
    </div>
  </section>
}

export default Article

Article.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired,
}
