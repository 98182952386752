import React from 'react'
import PropTypes from 'prop-types'

import SEO from './SEO'
import useBuildTime from '../hooks/useBuildTime'
import config from '../../config'
import { Link } from 'gatsby';

import "../style/site.css"
import "katex/dist/katex.min.css";

const OrangeLayout = ({children, defaultSEO, title, byline}) => { 
    const buildTime = useBuildTime();

    let bylineNode, titleNode;
    if(byline) {
        bylineNode = <div className="byline smallhead">{byline}</div>
    }
    else {
        bylineNode = [];
    }

    if(title) {
        titleNode = <header>
            <h1>{title}</h1>
        </header>;
    }

    return(<>
        { defaultSEO && <SEO buildTime={buildTime} />}
        <header id="top">
            <h1><Link to="/">{config.siteTitle}</Link></h1>
            <nav>
                <Link to="/blog/">Blog</Link>
                <Link to="/projects/">Projects</Link>
            </nav>
        </header>
        <article className="window">
            {titleNode}
            {bylineNode}
            <div className="content">
                {children}
            </div>
        </article>
        <footer id="bottom">
            <span>Last build: {buildTime}</span>
        </footer>
    </>);
};

export default OrangeLayout;

OrangeLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
    customSEO: PropTypes.bool,
    title: PropTypes.string
}
  
OrangeLayout.defaultProps = {
    defaultSEO: false
}